






































































import  Vue  from "vue";
import { getModule } from "vuex-module-decorators";

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import AnalyticsDateControl from "./AnalyticsDateControl.vue";

import { transformDataToCsvUrl } from "@/services/csv";
import { AnalyticsLivestream, AnalyticsLivestreamVisitor } from "@/types/interfaces";

export default Vue.extend ({

    components: {
        AnalyticsDateControl
    },
    computed: {

        loading(): boolean {
            return analyticsStore.loading;
        },
    
        sessionId(): string {
            return this.$route.params.sessionId ? this.$route.params.sessionId : "";
        },
    
        itemData(): AnalyticsLivestream {
            return analyticsStore.singleSpeakeasy;
        },
    
        users(): Array<AnalyticsLivestreamVisitor> {
            return this.itemData.users;
        },
    
        sessionName(): string {
            return this.itemData.sessionName;
        },
    
        tableHeaders(): Array<string> {
            return [
                "Attendee Id",
                "First Name",
                "Last Name",
                "Company Name",
                "Email",
                "Time Watched"
            ];
        },
    
        tableFooter(): Array<string> {
            return [
                "Total Watchers",
                `${this.totalVisitors}`,
                "",
                "",
                "Total Minutes Watched",
                `${this.totalMinutes}`
            ];
        },
    
        totalMinutes(): number {
            return this.users.reduce((acc, curr) => {
                return (acc += curr.minutes);
            }, 0);
        },
    
        csvUrl(): string {
            return this.getCsvUrl();
        },
    
        totalVisitors(): number {
            return this.users.length;
        },
    
        csvFilename(): string {
            return this.sessionName
                ? `exhibitor-${Vue.prototype.MgSimpleSlugify(this.sessionName)}.csv`
                : `exhibitor-${this.sessionId}`;
        }

    },
    methods: {
        getData() {
            analyticsStore.getSingleSpeakeasy(this.sessionId);
        },
    
        getCsvUrl() {
            const valuesOnly = this.users.map((item) => [
                item.uid,
                item.firstName,
                item.lastName,
                item.companyName,
                item.email,
                item.minutes ? `${item.minutes}` : "0"
            ]);
            const dataArray = [this.tableHeaders, ...valuesOnly, this.tableFooter];
    
            return transformDataToCsvUrl(dataArray);
        }
    },

    mounted() {
        this.getData();
    }

})
