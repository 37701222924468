















































































import Vue  from "vue";
import { getModule } from "vuex-module-decorators";

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import Spinners from "@/components/utilities/Spinners.vue";
import AnalyticsDateControl from "./AnalyticsDateControl.vue";

import { transformDataToCsvUrl } from "@/services/csv";
import { AnalyticsSpeakeasyItem } from "@/types/interfaces";


export default Vue.extend ({
    components: {
        Spinners,
       AnalyticsDateControl
    },
    computed: {

        loading(): boolean {
            return analyticsStore.loading;
        },
    
        videoData(): Array<AnalyticsSpeakeasyItem> {
            return analyticsStore.speakeasies;
        },
    
        sortedData(): Array<AnalyticsSpeakeasyItem> {
            return this.sortedDataMethod();
        },
    
        tableHeaders(): Array<string>{
            return [
                "Session ID",
                "Session Name",
                "# of Participants",
                "Total Minutes",
                "Longest Individual Time in Meeting",
                "First Participant in Room",
                "Last Participant in Room"
            ];
        },
    
        csvUrl(): string {
            return this.getCsvUrl();
        }
    },
    methods: {
        sortedDataMethod(){
            return this.videoData.sort((a, b) => b.minutes - a.minutes);
        },
        getData() {
            analyticsStore.getSpeakeasies();
        },
    
        getCsvUrl() {
            const valuesOnly = this.videoData.map((item) => [
                item.sessionId,
                item.sessionName,
                item.userCount,
                item.minutes,
                item.longestSingleUser,
                item.first,
                item.last
            ]);
            const dataArray = [this.tableHeaders, ...valuesOnly];
    
            return transformDataToCsvUrl(dataArray);
        }
    },
    mounted() {
        this.getData();
    }

})
